import dayjs from 'dayjs'
import 'dayjs/locale/id'
import 'dayjs/locale/ms'
import 'dayjs/locale/th'
import 'dayjs/locale/tl-ph'
import 'dayjs/locale/vi'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { LanguageCode } from '~/types'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

export const setTimezone = (timezone: string) => {
    $dayjs.tz.setDefault(timezone)
}

export const setLocale = (locale?: LanguageCode) => {
    if (!locale) {
        return
    }

    switch (locale) {
        case LanguageCode.EN:
            $dayjs.locale('en')
            break
        case LanguageCode.ID:
            $dayjs.locale('id')
            break
        case LanguageCode.MS:
            $dayjs.locale('ms')
            break
        case LanguageCode.TH:
            $dayjs.locale('th')
            break
        case LanguageCode.TL:
            $dayjs.locale('tl-ph')
            break
        case LanguageCode.VI:
            $dayjs.locale('vi')
            break
    }
}

export const $dayjs = dayjs
