import Cookies from 'js-cookie'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useLocale } from '~/hooks/useLocale'
import { shared } from '~/lib/shared'
import { LanguageCode } from '~/types'
import { getValueByPath } from '~/utils'
import { useAuth } from '../Auth/AuthProvider'

export const LanguageContext = createContext<{
    locale: LanguageCode
    langData: any
    t: (key: string, params?: Record<string, string>) => string
}>({
    locale: LanguageCode.EN,
    langData: {},
    t: () => ''
})

interface LanguageProviderProps {
    children: JSX.Element | JSX.Element[]
}

export const useLanguage = () => useContext(LanguageContext)

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
    const { profile } = useAuth()
    const [nextLocale, setNextLocale] = useState<LanguageCode>()

    const t = (key: string, params?: Record<string, string>) => {
        let text = getValueByPath(langData, key)

        if (text) {
            if (params) {
                Object.keys(params).forEach((key) => {
                    text = text.replace(new RegExp(`{${key}}`, 'g'), params[key])
                })

                return text
            }
        }

        return text || key
    }

    useEffect(() => {
        const cookieValue = Cookies.get('NEXT_LOCALE')

        if (cookieValue) {
            setNextLocale(cookieValue as LanguageCode)
        }
    }, [])

    const locale = useMemo(() => profile?.language_code || nextLocale, [profile?.language_code, nextLocale])

    useEffect(() => {
        shared.setRequestErrorMessage(t('common.requestErrorMessage'))
    }, [locale])

    const langData = useLocale(locale)

    return langData && locale ? (
        <LanguageContext.Provider key={locale} value={{ locale, t, langData }}>
            {children}
        </LanguageContext.Provider>
    ) : (
        <></>
    )
}
