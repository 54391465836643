import { RecaptchaVerifier } from '@firebase/auth'

declare module 'react' {
    interface SVGProps<T> extends AriaAttributes, DOMAttributes<T> {
        // For theme-ui
        times?: string
    }
}

declare global {
    interface Window {
        recaptchaVerifier: RecaptchaVerifier
        grecaptcha: any
        otpless: (user: {
            token: string
            timestamp: string
            timezone: string
            mobile: {
                name: string
                number: string
            }
            waNumber: string
            waName: string
        }) => void
        KelolaWebContainer?: {
            getToken: () => string
            getBusinessId: () => string
            showPaywall: () => void
        }
    }
    var AppChannel: any
    var Durianpay: Durianpay
}

interface DurianpayInitOptions {
    locale: string
    environment: string
    access_key: string
    site_name: string
    dark_mode: boolean
    pre_selected_payment_method: string
    pre_selected_payment_code: string
    back_url: string
    order_info: {
        id: string
        customer_info: {
            id: string
            email: string
            phone: string
            name: string
        }
    }
}

interface Durianpay {
    init(options: DurianpayInitOptions): DurianpayInstance
}

interface DurianpayInstance {
    checkout(): void
}

export type EVENTS_NAME =
    | 'otp_request'
    | 'otp_confirm'
    | 'business_create_tap'
    | 'business_create_submit'
    | 'business_join_tap'
    | 'business_join_submit_confirm'
    | 'product_save'
    | 'product_delete_confirm'
    | 'transaction_save'
    | 'staff_tap'
    | 'staff_save'
    | 'staff_code_share'
    | 'staff_delete_confirm'
    | 'business_edit_save'
    | 'third_party_save'

export * from './size'

export type StockActionT = 'stock_in' | 'stock_out' | 'stock_audit'

export interface MetaListI {
    meta: {
        limit: number
        offset: number
        total: number
    }
}

export interface PaginationI {
    limit?: number
    offset?: number
}

export interface ProductI {
    buyingPrice: number
    category?: CategoryI
    imageUrl: string
    name: string
    productId: string
    quantity: number
    sellingPrice: number
    sellingPrices?: ProductSellingPriceI[]
    sku: string
    expiredDate?: string
    description?: string
    updatedStock?: number
    productPriceID?: string
    customBuyingPrice?: number
    customSellingPrice?: number
    action?: StockActionT
}

export interface StockUpdateI {
    products: ProductI[]
    inAt: string
    note: string
    businessSupplierId?: string
    businessCustomerId?: string
}

export interface SortI {
    sortBy?: any
    orderBy?: 'ASC' | 'DESC' | 'asc' | 'desc'
}

export interface ProductFilterI {
    name?: string
    categoryId?: string[]
    sku?: string
}

export type ProductFilterQueryI = Omit<ProductFilterI, 'categoryId'> & {
    categoryId?: string
} & PaginationRequest &
    SortingRequest

export type ProductRequestBodyI = Omit<
    ProductI,
    'category' | 'updatedStock' | 'action' | 'sellingPrices' | 'productPriceID'
> & {
    categoryId?: string
}

export type ProductSellingPriceI = {
    id: string
    name: string
    price: number
}

export type ProductSellingPriceRequestBodyI = Omit<ProductSellingPriceI, 'id'> & {
    id?: string
    error?: string
    removed?: boolean
}

export interface CategoryI {
    categoryId: string
    name: string
}

export type BusinessAccessT =
    | 'stockIn'
    | 'stockOut'
    | 'stockAudit'
    | 'addProduct'
    | 'editProduct'
    | 'deleteProduct'
    | 'readCapitalPrice'
    | 'addCategory'
    | 'editCategory'
    | 'deleteCategory'
    | 'addStaff'
    | 'editStaff'
    | 'removeStaff'
    | 'changeBusinessProfile'
    | 'addBusinessCustomer'
    | 'editBusinessCustomer'
    | 'removeBusinessCustomer'
    | 'addBusinessSupplier'
    | 'editBusinessSupplier'
    | 'removeBusinessSupplier'
    | 'readAccountingReport'
    | 'readStockReport'
    | 'readCustomerReport'
    | 'all'
export type BusinessRoleT = 'owner' | 'admin' | 'staff'

export interface BusinessI {
    access: BusinessAccessT[]
    businessId: string
    category: string
    imageUrl: string
    name: string
    role: BusinessRoleT
    currencyCode: CurrencyCode
    countProduct?: number
    countStaff?: number
    userFields?: {
        totalEmployee: number
        omset: number
        warehouseCount: number
    }
    subscription?: {
        endDate: string
        productId: string
        startDate: string
    }
    createdAt?: string
}

export type BusinessRequestBodyI = Omit<BusinessI, 'access' | 'businessId' | 'role'>

export interface ProfileI {
    businesses: BusinessI[]
    imageUrl: string
    name: string
    phoneNumber: string
    email?: string
    language_code?: LanguageCode
}

export interface StaffI {
    access: BusinessAccessT[]
    imageUrl: string
    name: string
    phoneNumber: string
    email?: string
    staffId: string
    role: BusinessRoleT
}

export interface UpdateStaffI {
    access: BusinessAccessT[]
    staffId: string
    role?: BusinessRoleT
}

export interface StaffFilterI extends PaginationI {
    name?: string
    categoryId?: string
    sku?: number
}

export interface HistoryI {
    supplier?: SupplierI
    customer?: CustomerI
    historyId?: string
    detail: {
        id: string
        inAt: string
        createdAt: string
        note: string
        products: HistoryProductI[]
        type: StockActionT
    }
    user: {
        imageUrl: string
        name: string
        userId: string
    }
}

export type HistoryUpdateI = Omit<HistoryI, 'id' | 'detail' | 'supplier' | 'customer' | 'user'> & {
    historyId: string
    createdAt: string
    businessSupplierId?: string
    businessCustomerId?: string
} & HistoryI['detail']

export interface HistoryProductI {
    buyingPrice: number
    changeAmount: number
    lastQuantity: number
    imageUrl: string
    name: string
    productId: string
    sellingPrice: number
    sku: number
    expiredDate?: string
    description?: string
    isRemoved?: boolean
    isNew?: boolean
}

export interface HistoryFilterI extends PaginationI {
    inAt?: string
    staffId?: string[]
    transactionType?: StockActionT[]
    customerId?: string[]
    supplierId?: string[]
    timezone?: string
    startDate?: string
    endDate?: string
}

export interface ApiErrorI {
    message: string
}

export interface ApiResponseI<T> {
    data?: T
    error?: ApiErrorI
}

export interface AuthResponseI {
    isNew: boolean
    name: string
    phoneNumber: string
    profileUrl: string
    token: string
    email: string
}

export interface AccessToken {
    id: string
    name: string
}

export type GetRepsonseCollectionI<Key extends string, T> = {
    [key in Key]: T[]
} & MetaListI

export interface UpdateStockI {
    inAt: string
    note: string
    products: {
        amount?: number
        newQuantity?: number
        productId: string
        productPriceID?: string
        customBuyingPrice?: number
        customSellingPrice?: number
    }[]
    businessSupplierId?: string
    businessCustomerId?: string
}

export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
    ? ElementType
    : never

export interface CustomerI {
    address: string
    businessCustomerId?: string
    contact: string
    name: string
    note: string
}

export interface SupplierI {
    bankAccount?: {
        bankAccountName: string
        bankAccountNumber: string
        bankName: string
    }
    businessSupplierId?: string
    contact: string
    name: string
    note: string
}

export interface SupplierFilterI extends PaginationI {
    name?: string
}

export interface CustomerFilterI extends PaginationI {
    name?: string
}

export interface StockReportI {
    productId: string
    productDetail: {
        name: string
        sku: string
        buyingPrice: number
        sellingPrice: number
        quantity: number
        imageUrl: string
        categoryName: string
    }
    totalStockInAmount: number
    totalStockOutAmount: number
}

export interface StockReportFilterI {
    inAt?: string
    staffId?: string[]
    startDate?: string
    endDate?: string
}

export type StockReportFilterQueryI = ToQueryParamsObject<StockReportFilterI> & PaginationRequest & SortingRequest

export interface ProdcutTotalI {
    totalProduct: number
    totalProductQuantity: number
}

interface Address {
    receiver_name: string
    receiver_phone: string
    label: string
    address_line_1: string
    address_line_2: string
    city: string
    region: string
    country: string
    postal_code: string
    landmark: string
}

interface Customer {
    customer_ref_id?: string
    given_name: string
    email: string
    mobile: string
    address?: Address
}

interface Item {
    name: string
    qty: number
    price: string
    logo: string
}

export interface OrderReq {
    amount: string
    currency: string
    order_ref_id?: string
    customer: Customer
    items: Item[]
}

export interface OrderRes {
    id: string
    customer_id: string
    amount: string
    currency: string
    payment_option: string
    status: string
    order_ref_id: string
    address_id: number
    created_at: string
    updated_at: string
    metadata: Record<string, unknown>
    access_token: string
}

export interface CustomerReportFilterI {
    startDate?: string
    endDate?: string
}

export type CustomerReportFilterIQueryI = ToQueryParamsObject<CustomerReportFilterI> &
    PaginationRequest &
    SortingRequest

export interface CustomerReportI {
    customer: CustomerI
    totalTransaction: number
    totalAmount: number
    totalProfit: number
}

export type CollectionApiResponse<T, F extends string = 'data'> = {
    meta: MetaResponse
} & { [K in F]?: T[] }

export type CollectionApiParams<T = any> = T & PaginationRequest & SortingRequest

export interface SingleApiResponse<T> {
    result: boolean
    data: T
}

export interface PaginationRequest {
    offset?: number
    limit?: number
}

export interface PaginationResponse extends PaginationRequest {
    total: number
}

export interface SortingRequest {
    sortBy?: string
    orderBy?: 'ASC' | 'DESC'
}

export interface SortingResponse {
    sort_by?: string
    sort_order?: 'ASC' | 'DESC'
}

export type MetaResponse<T extends boolean = false> = T extends true
    ? SortingResponse & PaginationResponse
    : SortingRequest & PaginationResponse

type ConvertArrayToString<T> = T extends any[] ? string : T

export type ToQueryParamsObject<T> = {
    [K in keyof T]?: ConvertArrayToString<T[K]>
}

export enum CurrencyCode {
    IDR = 'IDR',
    MYR = 'MYR',
    THB = 'THB',
    SGD = 'SGD',
    PHP = 'PHP',
    VND = 'VND',
    KHR = 'KHR',
    BND = 'BND',
    LAK = 'LAK',
    MMK = 'MMK',
    USD = 'USD'
}

export interface CurrencyCodeConfig {
    name: string
    symbol: string
    decimals: number
    decimalSeparator: string
    thousandsSeparator: string
    roundType: 'round' | 'ceil' | 'floor'
    round: boolean
    noSpaceBetweenSymbolAndValue?: boolean
    minChartUpperBound?: number
}

export enum LanguageCode {
    EN = 'en', // English
    ID = 'id', // Indonesian
    MS = 'ms', // Malay
    TH = 'th', // Thai
    TL = 'tl', // Tagalog
    VI = 'vi' // Vietnamese
}

export interface LanguageCodeConfig {
    name: string
    code: string
}
