import { useEffect, useMemo, useState } from 'react'
import { useAuth } from '../Auth/AuthProvider'
import { useLanguage } from '../Language/LanguageProvider'
import { Modal } from '../Modal/Modal'
import { Button } from '../ui/button'
import { useSubscription } from './SubscriptionProvider'

interface SubscriptionLimitExceededProps {
    path: string
}
export const SubscriptionLimitExceeded = ({ path }: SubscriptionLimitExceededProps) => {
    const { currentBusiness } = useAuth()
    const { checkLimitExceeded } = useSubscription()
    const { t } = useLanguage()
    const [show, setShow] = useState(false)

    const checkLimit = () => {
        const result = checkLimitExceeded()

        if (path === '/business') {
            setShow(false)
            return
        }

        if (result.products || result.staff) {
            setShow(true)
        }
    }

    const handleClose = () => {
        if (config.showClose) {
            setShow(false)
        }
    }

    const config = useMemo(() => {
        if (currentBusiness()?.role === 'owner') {
            return {
                title: t('subscription.limit_exceeded_owner.title'),
                description: t('subscription.limit_exceeded_owner.description'),
                showClose: true
            }
        }
        return {
            title: t('subscription.limit_exceeded.title'),
            description: t('subscription.limit_exceeded.description'),
            showClose: false
        }
    }, [t])

    useEffect(() => {
        checkLimit()
    }, [path])

    return (
        <Modal
            size="sm"
            show={show}
            title={config.title}
            close={config.showClose}
            onClose={handleClose}
            action={config.showClose ? <Button onClick={handleClose}>{t('common.button.done')}</Button> : undefined}>
            <p>{config.description}</p>
        </Modal>
    )
}
