import { useRouter } from 'next/router'
import { useState } from 'react'

import { useAuth } from '~/components/Auth/AuthProvider'
import { useGlobal } from '~/components/Common/GlobalDataProvider'
import { Spacer } from '~/components/Common/Spacer'
import Icon from '~/components/Icon'
import $posthog from '~/lib/posthog'
import { BusinessI } from '~/types'
import { setCurrentBusiness } from '~/utils'
import { useLanguage } from '../Language/LanguageProvider'
import { PopupBusinessForm } from './PopupBusinessForm'
import { PopupJoinBusiness } from './PopupJoinBusiness'

export const BusinessAction = () => {
    const router = useRouter()
    const { loadProfile } = useAuth()
    const { setToastProps } = useGlobal()
    const [showBusinessForm, setShowBusinessForm] = useState(false)
    const [showJoinForm, setShowJoinForm] = useState(false)
    const { t } = useLanguage()
    const onSaveBusiness = async (business: BusinessI) => {
        await loadProfile()
        setCurrentBusiness(business.businessId)

        setToastProps({
            message: t('business.message.saved'),
            open: true,
            wrapper: 'right-hand-side'
        })

        window.location.href = '/business/product'
    }

    const createBusiness = () => {
        setShowBusinessForm(true)
        $posthog.capture('business_create_tap')
    }

    const joinBusiness = () => {
        setShowJoinForm(true)
        $posthog.capture('business_join_tap')
    }

    return (
        <>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-5 mx-auto max-w-3xl">
                <div
                    className="flex items-center border rounded-xl p-4 hover:bg-primary-50 transition-colors text-neutrals-900"
                    role="button"
                    onClick={createBusiness}>
                    <span className="p-2.5 bg-primary-100 rounded-lg text-primary mr-6 flex items-center justify-center">
                        <Icon name="store" width={36}></Icon>
                    </span>
                    <div className="mr-1">
                        <h3 className="font-bold mb-0.5 text-lg">{t('onboarding.action.add_business.title')}</h3>
                        <p className="text-sm">{t('onboarding.action.add_business.description')}</p>
                    </div>
                    <Spacer></Spacer>
                    <Icon name="chevron-right" width={30}></Icon>
                </div>
                <div
                    className="flex items-center border rounded-xl p-4 hover:bg-primary-50 transition-colors text-neutrals-900"
                    role="button"
                    onClick={joinBusiness}>
                    <span className="p-2.5 bg-green-100 rounded-lg text-green mr-6 flex items-center justify-center">
                        <Icon name="link" width={36}></Icon>
                    </span>
                    <div className="mr-1">
                        <h3 className="font-bold mb-0.5 text-lg">{t('onboarding.action.join_business.title')}</h3>
                        <p className="text-sm">{t('onboarding.action.join_business.description')}</p>
                    </div>
                    <Spacer></Spacer>
                    <Icon name="chevron-right" width={30}></Icon>
                </div>
            </div>

            <PopupBusinessForm
                show={showBusinessForm}
                onSave={(business) => onSaveBusiness(business)}
                onClose={() => setShowBusinessForm(false)}></PopupBusinessForm>
            <PopupJoinBusiness
                show={showJoinForm}
                onSave={() => router.push('/business')}
                onClose={() => setShowJoinForm(false)}></PopupJoinBusiness>
        </>
    )
}
