import jwt_decode from 'jwt-decode'
import {
    AccessToken,
    CollectionApiParams,
    CurrencyCode,
    CurrencyCodeConfig,
    LanguageCode,
    LanguageCodeConfig,
    PaginationRequest,
    SortingRequest,
    ToQueryParamsObject
} from '~/types'
import { localStore, StoreKey } from './local-store'

export const formatNumberToCurrencyString = (
    num = 0,
    currencyCode = CurrencyCode.IDR,
    withSymbol = true,
    showDecimals = true
) => {
    const currency = currencyConfig[currencyCode]

    if (!currency) {
        throw new Error(`Currency ${currencyCode} not found`)
    }

    let value = num

    const { symbol, decimals, decimalSeparator, thousandsSeparator, roundType, round, noSpaceBetweenSymbolAndValue } =
        currency

    if (round) {
        value = Math[roundType]((num + Number.EPSILON) * 100) / 100
    }

    let formattedValue = showDecimals
        ? value.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator) // Apply thousandsSeparator
        : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator) // Apply thousandsSeparator

    if (decimals > 0) {
        formattedValue = formattedValue.replace('.', decimalSeparator)
    }

    return withSymbol ? `${symbol}${noSpaceBetweenSymbolAndValue ? '' : ' '}${formattedValue}` : formattedValue
}

export const isNumberOnly = (number: string) => {
    const numberRegx = /^\d+$/
    return number.match(numberRegx)
}

export const delayedCallback = (callback: () => void, timeout = 150) => {
    setTimeout(() => {
        callback()
    }, timeout)
}

export const padTime = (time: number) => {
    return String(time).length === 1 ? `0${time}` : `${time}`
}

export const formatTime = (time: number) => {
    // Convert seconds into minutes and take the whole part
    const minutes = Math.floor(time / 60)

    // Get the seconds left after converting minutes
    const seconds = time % 60

    //Return combined values as string in format mm:ss
    return `${minutes}:${padTime(seconds)}`
}

export const convertToNumberWithCurrency = (value: string, currencyCode: CurrencyCode) => {
    const { decimals } = currencyConfig[currencyCode]

    return Number(value.replace(/\D/g, '').replace(/\b0+/g, '')) / 10 ** decimals
}

export const convertToNumber = (value: string) => {
    return Number(value.replace(/\D/g, '').replace(/\b0+/g, ''))
}

export const SkuIDGenerator = () => {
    const random = Math.floor(1000000000 + Math.random() * 9000000000).toString()
    return '123' + random
}

export const GetCustomerShortNames = (name: string) => {
    if (!name) {
        return '-'
    }

    return name
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), '')
        .toUpperCase()
        .substring(0, 3)
}

export const getCurrentBusiness = () => {
    const business = localStore.get(StoreKey.SELECTED_BUSINESS)?.data

    if (!business) {
        return ''
    }

    return business
}

export const setCurrentBusiness = (businessId: string) => {
    localStore.set(StoreKey.SELECTED_BUSINESS, businessId)
}

export const unsetCurrentBusiness = () => {
    localStore.remove(StoreKey.SELECTED_BUSINESS)
}

export const getAccessToken = (token?: string): AccessToken | null => {
    const accessToken = !token ? localStore.get(StoreKey.ACCESS_TOKEN)?.data : token

    try {
        return jwt_decode(accessToken!)
    } catch {
        return null
    }
}

export const difference = (a: number, b: number) => {
    return Math.abs(a - b)
}

export const isElementInViewport = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect()

    return isInViewport(rect.top, rect.right, rect.bottom, rect.left)
}

export const isInViewport = (_top: number, _right: number, _bottom: number, _left: number) => {
    const top = _top >= 0
    const right = _right <= (window.innerWidth || document.documentElement.clientWidth)
    const bottom = _bottom <= (window.innerHeight || document.documentElement.clientHeight)
    const left = _left >= 0

    return {
        top,
        right,
        bottom,
        left,
        all: top && left && bottom && right
    }
}

type MultiPromiseResolver = <T extends any[]>(promises: { [K in keyof T]: Promise<T[K]> }) => Promise<{
    [K in keyof T]: { status: 'fulfilled' | 'rejected'; value?: T[K]; reason?: any }
}>

export const multiPromiseResolver: MultiPromiseResolver = <T extends any[]>(promises: {
    [K in keyof T]: Promise<T[K]>
}) => {
    return Promise.all(
        promises.map((promise) =>
            promise
                .then((value) => ({ status: 'fulfilled' as const, value }))
                .catch((reason) => ({ status: 'rejected' as const, reason }))
        )
    ) as Promise<{ [K in keyof T]: { status: 'fulfilled' | 'rejected'; value?: T[K]; reason?: any } }>
}

export const replaceToPhoneNumberFormat = (text: string) => {
    return text.replace(/[^0-9+]/g, '').replace(/\b0+/g, '')
}

export const countryCodeRegex = /^(?!(?:62|97))/g

export const generateRandomSkuHash = () => {
    const length = Math.floor(Math.random() * 3) + 6 // Random length between 6 and 8
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const numbers = '0123456789'

    let result = ''

    // Start with 2 characters
    for (let i = 0; i < 2; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length))
    }

    // Followed by numbers
    for (let i = 2; i < length; i++) {
        result += numbers.charAt(Math.floor(Math.random() * numbers.length))
    }

    return result
}

export const randomNumber = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export const convertToQueryParams = <T extends object, M extends ToQueryParamsObject<CollectionApiParams<T>>>(
    params: CollectionApiParams<T>,
    callback: (queryString: string, queryObject: M) => void,
    skippedKeys: (keyof CollectionApiParams<T>)[] = []
) => {
    const query = new URLSearchParams()
    const queryObject = {} as M

    Object.keys(params).forEach((key) => {
        const typedKey = key as keyof CollectionApiParams<T>
        const queryValue = params[typedKey]

        if (skippedKeys.includes(typedKey)) {
            return
        }

        if (queryValue) {
            query.append(key, queryValue.toString())
            queryObject[typedKey] = queryValue.toString() as unknown as M[keyof CollectionApiParams<T>]
        } else if (queryValue === false) {
            query.append(key, 'false')
        }
    })

    const queryString = query.toString()

    callback(queryString, queryObject)

    return queryString
}

export const defaultPagination: PaginationRequest = {
    offset: 0,
    limit: 20
}

export const defaultSort: SortingRequest = {
    sortBy: 'name',
    orderBy: 'ASC'
}

export const currencyConfig: Record<CurrencyCode, CurrencyCodeConfig> = {
    [CurrencyCode.IDR]: {
        name: 'Indonesian Rupiah',
        symbol: 'Rp',
        decimals: 0,
        decimalSeparator: ',',
        thousandsSeparator: '.',
        roundType: 'round',
        round: true,
        noSpaceBetweenSymbolAndValue: true,
        minChartUpperBound: 900
    },
    [CurrencyCode.MYR]: {
        name: 'Malaysian Ringgit',
        symbol: 'RM',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        noSpaceBetweenSymbolAndValue: true,
        minChartUpperBound: 90
    },
    [CurrencyCode.THB]: {
        name: 'Thai Baht',
        symbol: '฿',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true
    },
    [CurrencyCode.SGD]: {
        name: 'Singapore Dollar',
        symbol: '$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true
    },
    [CurrencyCode.PHP]: {
        name: 'Philippine Peso',
        symbol: '₱',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true
    },
    [CurrencyCode.VND]: {
        name: 'Vietnamese Dong',
        symbol: '₫',
        decimals: 0,
        decimalSeparator: '',
        thousandsSeparator: '.',
        roundType: 'round',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true
    },
    [CurrencyCode.KHR]: {
        name: 'Cambodian Riel',
        symbol: '៛',
        decimals: 0,
        decimalSeparator: '',
        thousandsSeparator: '.',
        roundType: 'round',
        round: true,
        minChartUpperBound: 900,
        noSpaceBetweenSymbolAndValue: true
    },
    [CurrencyCode.BND]: {
        name: 'Brunei Dollar',
        symbol: '$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true
    },
    [CurrencyCode.LAK]: {
        name: 'Lao Kip',
        symbol: '₭',
        decimals: 0,
        decimalSeparator: '',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true
    },
    [CurrencyCode.MMK]: {
        name: 'Myanmar Kyat',
        symbol: 'Ks',
        decimals: 0,
        decimalSeparator: '',
        thousandsSeparator: '.',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true
    },
    [CurrencyCode.USD]: {
        name: 'US Dollar',
        symbol: '$',
        decimals: 2,
        decimalSeparator: '.',
        thousandsSeparator: ',',
        roundType: 'ceil',
        round: false,
        minChartUpperBound: 90,
        noSpaceBetweenSymbolAndValue: true
    }
}

export const languageConfig: Record<LanguageCode, LanguageCodeConfig> = {
    [LanguageCode.ID]: {
        name: 'Bahasa Indonesia',
        code: 'id'
    },
    [LanguageCode.TH]: {
        name: 'ภาษาไทย',
        code: 'th'
    },
    [LanguageCode.TL]: {
        name: 'Tagalog',
        code: 'tl'
    },
    [LanguageCode.MS]: {
        name: 'Bahasa Melayu',
        code: 'ms'
    },
    [LanguageCode.VI]: {
        name: 'Tiếng Việt',
        code: 'vi'
    },
    [LanguageCode.EN]: {
        name: 'English',
        code: 'en'
    }
}

export const getLanguageConfig = (value: string) => {
    return Object.entries(languageConfig).find(([key, item]) => {
        return item.code === value
    })?.[1]
}

export const getValueByPath = (data?: Record<string, any>, path?: string): any => {
    if (!data || !path) return undefined

    const keys = path.split('.')
    return keys.reduce((acc, key) => acc && acc[key], data)
}
