import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { $dayjs } from './dayjs'

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export const convertLocalToUTC = (date: string, format = 'YYYY-MM-DD[T]HH:mm:ss.SSS') => {
    return $dayjs.tz(date).utc().format(format)
}
